import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import ExhibitionPreview from '../../exhibitionPreview/exhibitionPreview'
import * as styles from './styles.module.scss'

export default function ArchivedExhibitions() {
  const compare = (a, b) => {
    if (a.node.order > b.node.order) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  }

  return (
    <StaticQuery
      query={graphql`
        query ArchivedExhibitions {
          allContentfulArchivedExhibition {
            edges {
              node {
                featuredImage {
                  file {
                    fileName
                    url
                  }
                }
                title
                date
                order
              }
            }
          }
        }
      `}
      render={result => {
      const data = result.allContentfulArchivedExhibition.edges

        return (
          <div className={styles.root}>
            <div className={styles.title}>Archive</div>
            <div className={`grid-parent ${styles.inner}`}>
              {data.sort(compare).map((node, index) => {
                return (
                  <div key={index} className={styles.block}>
                    <ExhibitionPreview
                      content={node.node}
                      fontSize='14px'
                      newTab='true'
                      key={node.node.title}
                      fixedSize={true}
                    />
                  </div>
                )
              }
              )}
            </div>
          </div>
        )
      }}
    />
  )
}