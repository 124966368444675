import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import ExhibitionPreview from '../../exhibitionPreview/exhibitionPreview'
import * as styles from './styles.module.scss'

const PastExhibitions = ({content}) => {
  const compare = (a, b) => {
    if (a.node.order > b.node.order) {
      return -1;
    } else {
      return 1;
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>Past</div>
      <div className="grid-parent">
        {content.sort(compare).map((node, index) =>
          <div key={index} className={`threeup ${styles.block}`}>
            <ExhibitionPreview
              content={node.node}
              type='3-up'
              url={`/exhibition/${node.node.slug}`}
              key={node.node.slug}
              fixedSize={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PastExhibitions