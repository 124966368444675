import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import CurrentExhibition from '../../components/sections/currentExhibition/currentExhibition'
import UpcomingExhibition from '../../components/sections/upcomingExhibition/upcomingExhibition'
import PastExhibitions from '../../components/sections/pastExhibitions/pastExhibitions'
import ArchivedExhibitions from '../../components/sections/archivedExhibitions/archivedExhibitions'

const ExhibitionsPage = ({ pageContext }) => {
  const { content } = pageContext

  return (
    <Layout>
      <Seo title="Exhibitions" />
      <div>
        {
          content.filter(x => !x.node.isUpcoming).filter(x => x.node.isCurrent).length ?
          <CurrentExhibition content={content.filter(x => !x.node.isUpcoming).filter(x => x.node.isCurrent)[0]} /> :
          null
        }

        {
          content.filter(x => x.node.isUpcoming).length ?
          <UpcomingExhibition content={content.filter(x => x.node.isUpcoming)[0]} /> :
          null
        }

        {
          content.filter(x => !x.node.isUpcoming).filter(x => !x.node.isCurrent).length ?
          <PastExhibitions content={content.filter(x => !x.node.isUpcoming).filter(x => !x.node.isCurrent)} /> :
          null
        }

        <ArchivedExhibitions />
      </div>
    </Layout>
  )
}


export default ExhibitionsPage
