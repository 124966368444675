import * as React from "react"

import ExhibitionPreview from '../../exhibitionPreview/exhibitionPreview'
import * as styles from './styles.module.css'

const CurrentExhibition = ({content}) => {
  const data = content.node

  return (
    <div className={styles.root}>
      <div className={styles.title}>Current</div>
      <ExhibitionPreview
        content={data}
        url={`/exhibition/${data.slug}`}
        width='3'
        fixedSize={true}
      />
    </div>
  )
}

export default CurrentExhibition
